import { FooterPage } from "./styles";
import { useNavigate } from "react-router-dom";

export const Footer = () => {
  const navigate = useNavigate();

  return (
    <FooterPage>
      <div>
        <h3> LINKS ÚTEIS</h3>
        <div>
          <a
            href="https://www.marinha.mil.br/chm/dados-do-segnav/folhetos"
            target="_blank"
            rel="noreferrer"
          >
            Aviso aos navegantes
          </a>
          <a
            href="https://www.marinha.mil.br/chm/dados-do-segnav-publicacoes/catalogo-de-cartas-e-publicacoes"
            target="_blank"
            rel="noreferrer"
          >
            Catálogo de cartas náuticas Da DHN
          </a>
          <a
            href="https://www.admiralty.co.uk/publications/admiralty-digital-catalogue"
            target="_blank"
            rel="noreferrer"
          >
            Catálogo Admiralty
          </a>
        </div>
      </div>
      <div>
        <h3>SUPORTE</h3>
        <a href="#sobre">Sobre nós</a>
        <p onClick={() => navigate("/Contato")}>Fale Conosco </p>
        <a
          href="https://wa.me/552135804252?text=Oi!%20Vim%20do%20site%20e%20desejo%20receber%20atendimento"
          target="_blank"
          rel="noreferrer"
        >
          Whatsapp
        </a>
      </div>
      <div>
        <h3>ATENDIMENTO</h3>
        <p onClick={() => navigate("/Contato")}>(21)98273-9593</p>
        <p onClick={() => navigate("/Contato")}>(21)3021-3886</p>
        <p onClick={() => navigate("/Contato")}>(21)3580-4252</p>
      </div>
    </FooterPage>
  );
};
