import styled from "styled-components";
import horizon from "../../assets/img/ocean-horizon.jpg";

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;

  .carousel-root {
    padding: 0 15px;
  }

  #carousell {
    background-image: url("${horizon}");
    background-size: cover;
    padding: 40px;

    figure {
      width: 100%;
      height: 100%;
      background-color: white;

      img {
        width: 70%;
      }
    }
  }

  #sobre {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 15px 40px 15px;
    border-bottom: inset;

    div {
      max-width: 500px;
      padding: 20px 10px;
      margin-top: 40px;
      border-radius: 7px;

      h1 {
        margin-bottom: 10px;
        color: var(--Color-primary);
      }

      p {
        color: var(--Black-1);
        line-height: normal;
      }

      div {
        padding: 0;
        margin-top: 0;
        box-shadow: none;
      }
    }

    #empresa {
      figure {
        margin-top: 30px;
        text-align: end;
        cursor: pointer;

        a {
          img {
            width: 100px;
          }
        }
      }
    }

    #empresaImg {
      width: 100%;
      max-width: 400px;
      margin-top: 20px;

      img {
        width: 100%;
      }
    }
  }

  article {
    margin: 40px 0;
    text-align: center;
    padding: 0 15px;

    h1 {
      font-family: "Lobster", cursive;
      font-size: 22px;
      color: var(--Color-primary);
      font-weight: bold;
    }

    h2 {
      font-family: "Lobster", cursive;
      font-size: 22px;
      line-height: 27px;
    }

    p {
      margin-top: 10px;
      line-height: 17px;
      color: var(--Black-1);
    }
  }

  #info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    background-color: var(--Color-primary);
    height: 650px;
    margin-bottom: 50px;

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: var(--White);
      width: 90%;
      padding: 15px;
      border-radius: 5px;
      margin: 10px 0;
      text-align: center;
      max-width: 425px;

      figure {
        img {
          width: 40px;
        }
      }

      div {
        margin-top: 10px;
        padding: 0;

        a {
          text-decoration: none;
          cursor: pointer;
        }
      }

      p {
        color: var(--Black-1);
        line-height: 17px;
        font-size: 14px;
        margin-bottom: 10px;
        text-align: center;
      }

      button {
        background-color: var(--Color-primary);
        color: var(--White);
        border: 2px solid var(--Color-primary);
        border-radius: 5px;
        padding: 5px;
      }

      button:hover {
        background-color: var(--White);
        color: var(--Color-primary);
      }
    }

    h3 {
      color: var(--Color-primary);
      line-height: 37px;
    }

    span,
    a {
      color: var(--Black-1);
      line-height: 17px;
      font-size: 14px;
    }
  }

  @media (min-width: 425px) {
    #info {
      width: 100%;
    }

    #carousell {
      figure {
        width: 100%;
        background-color: white;

        img {
          width: 70%;
        }
      }
    }

    p {
      font-size: 16px;
    }
  }

  @media (min-width: 768px) {
    #info {
      div {
        height: 259px;
        p,
        span,
        a {
          line-height: 20px;
          font-size: 16px;
        }
      }
    }
  }

  @media (min-width: 1024px) {
    #sobre {
      width: 100%;
      padding: 0 35px 40px 35px;
      flex-direction: row;
      justify-content: space-around;
    }

    #carousell {
      img {
      }

      .carousel-root {
        padding: 0 15px;
      }
    }

    #info {
      height: 450px;
      flex-direction: row;
    }
  }

  @media (min-width: 1440px) {
    #sobre {
      h1 {
        font-size: 24px;
      }

      p {
        font-size: 18px;
      }

      #empresa figure a img {
        width: 130px;
      }

      #empresaImg {
        max-width: 450px;
      }
    }

    #carousell {
      img {
      }

      .carousel-root {
        padding: 0 15px;
      }
    }

    #info {
      div {
        max-width: 540px;
        height: 280px;

        figure img {
          width: 60px;
        }
        h3 {
          font-size: 20px;
        }

        p {
          margin: 20px 0;
        }

        button {
          width: 110px;
          height: 35px;
        }
      }
    }
  }
`;
