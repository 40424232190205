import styled from "styled-components";

export const HeaderDesktop = styled.header`
  display: none;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px;
  background-color: var(--Grey-1);
  box-shadow: 0px -2px 41px -4px rgba(98, 98, 98, 0.76);
  -webkit-box-shadow: 0px -2px 41px -4px rgba(98, 98, 98, 0.76);
  -moz-box-shadow: 0px -2px 41px -4px rgba(98, 98, 98, 0.76);

  figure {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 210px;
    padding: 0 20px;
    cursor: pointer;

    img {
      width: 60px;
      position: relative;
      top: -5px;
    }

    h1 {
      font-family: "Lobster", cursive;
      font-size: 20px;
      color: var(--Color-primary);
      font-weight: bold;
    }
  }

  nav {
    a {
      position: relative;
      color: var(--Black-0);
      text-decoration: none;
      margin: 0 10px;
      padding: 0px 15px;
      font-size: 16px;
    }

    a:after {
      content: "";
      position: absolute;
      background-color: var(--Color-primary);
      height: 3px;
      width: 0;
      left: 0;
      bottom: -10px;
      transition: 0.5s;
    }
    a:hover:after {
      width: 100%;
    }
  }

  @media (min-width: 768px) {
    display: flex;
  }
`;

export const HeaderMobile = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--Grey-1);
  padding: 20px 15px;
  margin-bottom: 25px;
  border-radius: 0 0 10px 10px;
  box-shadow: 0px -2px 41px -4px rgba(98, 98, 98, 0.76);
  -webkit-box-shadow: 0px -2px 41px -4px rgba(98, 98, 98, 0.76);
  -moz-box-shadow: 0px -2px 41px -4px rgba(98, 98, 98, 0.76);

  figure {
    cursor: pointer;

    img {
      width: 30px;
    }

    #logo {
      width: 50px;
    }

    #logo:hover {
      filter: brightness(1.1);
    }
  }

  div {
    position: fixed;
    top: 0;
    left: 45%;
    width: 55%;
    height: 100%;
    overflow: hidden;
    z-index: 999999;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: var(--White);
    box-shadow: 260px -1px 278px 75px rgba(0, 79, 255, 0.75) inset;
    -webkit-box-shadow: 260px -1px 278px 75px rgba(0, 79, 255, 0.75) inset;
    -moz-box-shadow: 260px -1px 278px 75px rgba(0, 79, 255, 0.75) inset;
    border-radius: 230px 0px 0px 230px;

    nav {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 2rem;
      color: white;

      a {
        position: relative;
        color: var(--White);
        text-shadow: 4px 10px 13px var(--Color-primary);
        text-decoration: none;
        padding: 0px 5px;
        font-size: 24px;
      }

      a:after {
        content: "";
        position: absolute;
        background-color: var(--White);
        height: 3px;
        width: 0;
        left: 0;
        bottom: -10px;
        transition: 0.3s;
      }
      a:hover:after {
        width: 100%;
      }
    }

    figure {
      img {
        width: 40px;
        position: absolute;
        top: 1.5rem;
        right: 1rem;
      }
    }
  }

  @media (min-width: 425px) {
    div {
      left: 55%;
      width: 45%;
    }
  }

  @media (min-width: 525px) {
    div {
      left: 65%;
      width: 35%;
    }
  }

  @media (min-width: 768px) {
    display: none;
  }
`;
