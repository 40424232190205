import React, { useContext, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { ListContext } from "../../Provider/List";
import { useNavigate } from "react-router-dom";

function Items({ currentItems }) {
  const navigate = useNavigate();

  return (
    <ul id="listProducts">
      {currentItems &&
        currentItems.map((product, index) => (
          <li
            key={index}
            id={product.name}
            onClick={() => navigate("/Contato")}
          >
            <figure>
              <img src={product.img} alt={product.name} />
            </figure>
            <span>{product.category}</span>
            <div>
              <p>{product.name.toLowerCase()}</p>
            </div>
          </li>
        ))}
    </ul>
  );
}

export function PaginatedItems({ itemsPerPage }) {
  const { Lists, setItemOffset, itemOffset } = useContext(ListContext);

  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);

  // const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(Lists.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(Lists.length / itemsPerPage));
  }, [Lists, itemOffset, itemsPerPage]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % Lists.length;
    console.log(
      "🚀 ~ file: index.js:48 ~ handlePageClick ~ newOffset",
      newOffset
    );
    setItemOffset(newOffset);
  };

  return (
    <>
      <Items currentItems={currentItems} />
      <ReactPaginate
        breakLabel="..."
        nextLabel="Avançar"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="Voltar"
        renderOnZeroPageCount={null}
      />
    </>
  );
}
