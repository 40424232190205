import { A } from "./styles"
import whatsapp from "../../assets/img/whatsapp.svg"

export const Whatsapp = () => {

    return(
        <A 
        href="https://wa.me/552135804252?text=Oi!%20Vim%20do%20site%20e%20desejo%20receber%20atendimento" 
        target="_blank">
            <img src={whatsapp} alt="Fale Conosco pelo WhatsApp"/>
        </A>

    )
}