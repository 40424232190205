import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet"
import L from "leaflet"
import { Div } from "./styles"

export const Location = () => {

    const getIcon = (_iconSize) => {
        return L.icon({
            iconUrl: require("../../assets/img/location.png"),
            iconSize: _iconSize
        })
    }

    const position = [-22.917899716273244, -43.3790436206178]

    return(
        <Div id="mapa">
            <MapContainer id="mapaHeight" center={position} zoom={15} style={{position: "relative"}}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={position} icon={getIcon(40)}>
                    <Popup>
                        Barramar <br /> Distribuidor Autorizado
                    </Popup>
                </Marker>
            </MapContainer>
        </Div>
    )

}