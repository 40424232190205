import React from "react";
import Carousel from "react-elastic-carousel";
import IAMSARI from "../../assets/img/imo/IAMSAR Volume I, 2022 Edition IK960E.jpg";
import IAMSARII from "../../assets/img/imo/IAMSAR Volume II, 2022 Edition IH961E.jpg";
import IAMSARIII from "../../assets/img/imo/IAMSAR Volume III, 2022 Edition IK962E.jpg";
import MARPOL from "../../assets/img/imo/MARPOL Consolidated Edition, 2022.jpg";
import Procedures from "../../assets/img/imo/Procedures for port State control 2021, 2022 Edition IE650E.jpg";
import SOLAS from "../../assets/img/imo/SOLAS Consolidated Edition, 2020 IG110E.jpg";
import { Div, Item } from "./styles";
import "./styles.css";

export const CarouselComponent = () => {
  return (
    <Div>
      <Carousel>
        <Item>
          <img src={SOLAS} alt="SOLAS" />
        </Item>
        <Item>
          <img src={MARPOL} alt="IAMSARI" />
        </Item>
        <Item>
          <img src={IAMSARI} alt="IAMSARI" />
        </Item>
        <Item>
          <img src={IAMSARII} alt="IAMSARI" />
        </Item>
        <Item>
          <img src={IAMSARIII} alt="IAMSARI" />
        </Item>
        <Item>
          <img src={Procedures} alt="IAMSARI" />
        </Item>
      </Carousel>
    </Div>
  );
};
