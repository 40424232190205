import { useNavigate } from "react-router-dom";
import { Footer } from "../../Components/Footer";
import { Header } from "../../Components/Header";
import { Main } from "./styles";
import { CarouselComponent } from "../../Components/Carrossel";
import imo from "../../assets/img/imo.jpg";
import service from "../../assets/img/service.png";
import support from "../../assets/img/support.png";
import carga from "../../assets/img/carga.jpg";

const Home = () => {
  const navigate = useNavigate();

  return (
    <>
      <Header />
      <Main>
        <CarouselComponent />
        <section id="sobre">
          <div id="empresa">
            <div>
              <h1>A empresa</h1>
              <p>
                "Somos uma empresa há mais de 10 anos no mercado marítimo
                offshore, uma entre as 3 distribuidoras oficiais da IMO
                (International Maritime Organization) espalhadas pelo Brasil,
                credenciada como agente de vendas da DHN (Diretoria de
                Hidrografia e Navegação) para o fornecimento e atualização de
                cartas e publicações náuticas. Fornecemos materiais de qualidade
                com certificados, excelentes preços e prazos com o objetivo de
                ser coerente com o mercado, transparentes e corretos com os
                clientes que buscam nossos serviços. Temos como prioridade
                oferecer um pronto atendimento sempre entregando solução e
                qualidade."
              </p>
            </div>
            <figure>
              <a href="https://www.imo.org/en" target="_blank" rel="noreferrer">
                <img src={imo} alt="Logo da imo" />
              </a>
            </figure>
          </div>
          <figure id="empresaImg">
            <img src={carga} alt="Navio de carga" />
          </figure>
        </section>
        <article>
          <h1>BarraMar</h1>
          <h2>Cartas e publicações náuticas</h2>
          <p>Pronto atendimento com melhores preços e prazos de entrega.</p>
        </article>
        <section id="info">
          <div>
            <figure>
              <img src={service} alt="Ver os serviços" />
            </figure>
            <h3>SERVIÇOS</h3>
            <p>
              Atualização de Cartas Náuticas • Atualização de Publicações:
              Roteiros, Lista de Faróis, Catálogos de Cartas e Publicações,
              Lista de Auxílios-Rádio, Lista de Sinais Cegos • Reparo e Aferição
              de Barômetro • Reparo e Compensação de Agulha Magnética
            </p>
            <button onClick={() => navigate("/Produtos")}>Produtos</button>
          </div>
          <div>
            <figure>
              <img src={support} alt="Ver os conatatos" />
            </figure>
            <h3>SUPORTE</h3>
            <span>Tels.: 55 (21) 3021-3886 / 98273-9593 / 3580-4252</span>
            <span>Estrada do Rio Grande, 868 Bloco 4 sala 410 - Taquara</span>
            <div>
              <a href="mailto: vendas@barramarnautica.com.br?subject=subject text">
                vendas@barramarnautica.com.br
              </a>
              <a href="mailto: barramar.contato@barramarnautica.com.br?subject=subject text">
                barramar.contato@barramarnautica.com.br
              </a>
              <a href="mailto: financeiro@barramarnautica.com.br?subject=subject text">
                financeiro@barramarnautica.com.br
              </a>
            </div>
            <button onClick={() => navigate("/Contato")}>Fale Conosco</button>
          </div>
        </section>
      </Main>
      <Footer />
    </>
  );
};

export default Home;
