import ocean from "../../assets/img/ocean-horizon.jpg";
import styled from "styled-components";

export const Div = styled.div`
  width: 100%;
  padding: 20px;
  background-image: url("${ocean}");
  background-size: cover;

  @media (min-width: 768px) {
    background-position-y: -140px;
  }
  @media (min-width: 1024px) {
    background-position-y: -310px;
  }
  @media (min-width: 1440px) {
    background-position-y: -480px;
  }
  @media (min-width: 1850px) {
    background-position-y: -650px;
  }
`;

export const Item = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
  /* width: 100%; */
  background-color: var(--Color-primary);
  color: #fff;
  margin: 15px;
  font-size: 4em;

  img {
    height: 100%;
  }

  .sc-papXJ,
  .yXnOA,
  .rec,
  .rec-arrow,
  .rec,
  .rec-arrow-left {
    background-color: var(--Color-primary);
  }
`;
