import { Footer } from "../../Components/Footer"
import {Header} from "../../Components/Header"
import { Main } from "./styles"

const Company = () => {

    return(
        <>
            <Header />
            <Main/>
            <Footer/>
        </>
    )
}

export default Company